.m-header-info {
  padding: 0px 15px;
}

.line-black {
  background-color: black !important;
}
.select-match-dropdown {
  max-width: 300px;
}

/* Tipper room css  */
.chat-container {
  height: 70vh;
}

.pertipper-left {
  max-width: 40%;
}

.chat-input {
  height: 50px;
}
.chat-input-container {
  position: absolute;
  bottom: 1.8rem;
  width: 100%;
  gap: 1rem;
}
.tips-container {
  height: calc(100% - 90px);
  overflow-y: scroll;
  background-color: azure;
  padding-top: 1rem;
}

.message-box {
  background-color: white;
  max-width: 90%;
  margin: auto;
  border-radius: 5px;
  font-size: 14px;
}

.drag-active {
  background-color: rgba(119, 175, 239, 0.705) !important;
}

.drag-active-list {
  background-color: brown;
}
a:hover {
  color: black;
}
a:active {
  background-color: white;
  color: black;
}

.pointer {
  cursor: pointer;
}

.audio-recorder {
  background-color: white;
}
.detailed-view {
  /* width: 200px; */
  margin: auto;
  background-color: rgba(97, 152, 235, 0.765);
}
.detailed-view-table {
  background-color: rgba(165, 197, 246, 0.765) !important;
}

.agent-match-screen{
  background-image: linear-gradient(to top, #ffffff, #f7f7f8, #eeeef1, #e5e6ea, #dcdee3);
}
.score-view-container {
  width: 100%;
  min-height: 170px;
  /* background-color: rgb(48, 65, 110); */
  background-image: linear-gradient(to bottom, #30416e, #374979, #3e5185, #455a90, #4c629c);
  margin-bottom: 10px;
  padding: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
}
.run-rate-container {
  font-size: 12px;
  font-weight: 500;
  background-color: rgba(128, 130, 133, 0.542);
  background-image: linear-gradient(to bottom, #353536, #3b3b3c, #404042, #464648, #4c4c4e);
  border-radius: 6px;
  padding: 5px 10px;
}
.overs {
  font-size: 14px;
  font-weight: 500;
}
.team-score {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 10px;
  width: max-content;
  background-color: rgba(128, 130, 133, 0.542);
  border-radius: 8px;
  position: relative;
}
.player-info{
  padding: 5px;
  font-size: .8rem;
  border-radius: 6px;
  margin-top: 3px;

}
.ball {
  font-size: 2rem;
  font-weight: 600;
  color: rgb(214, 180, 46);
}
.event-grid {
  min-height: 130px;
  width: 100%;
  border-radius: 6px;
  /* max-width: 95%; */
  /* margin: auto; */
  padding: 0rem 1rem 0rem 1rem;
}
.event-block {
  height: 60px;
  margin-right: 5px;
  border-radius: 6px;
  margin-bottom: 1rem;
  /* background-color: rgb(182, 217, 248); */
  background-image: linear-gradient(to right, #b6d9f8, #b0d6f6, #aad3f4, #a3d0f1, #9dcdef);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.2rem;
  color: black;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
.event-block:hover{
  transform: translate(0, -10px);
}
.event-block-sm{
 height: 50px;
 max-width: 90px ;
 font-size: .9rem;
}

.event-key-text {
  font-size: .9rem;
}
.event-key-text-sm {
  font-size: .7rem;
}

.active-event {
  /* background-color: #3F979B; */
  background-image: linear-gradient(to right, #3f979b, #42a2a3, #46ada9, #4bb9b0, #52c4b5);
  transform: scale(1.07);
  z-index: 3;
}

.expression-grid {
  /* max-width: 800px; */
  padding: 0rem 1rem 0.5rem 0rem;
  margin: auto;
  display: flex;
  /* align-items: flex-start; */
  justify-content: space-between;
}
.expression-block {
  background-color: rgb(127, 126, 126);
  color: white;
  margin: 0 6px 6px 0;
}
.expression-block:hover {
  color: white;
}

.active-expression {
  background-color: #3F979B;
}

.expression-key-code {
  font-size: 12px;
}
.modal-event {
  min-width: 150px;
  height: 70px;
  border-radius: 5px;
  background-color: rgb(204, 207, 209);
  margin: 0 10px 10px 0;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
}

.other-btn {
  min-height: 70px;
  background-color: rgb(48, 48, 48);
}

.no-scroll{
  overflow-y: hidden !important;
}

.over-slider{
  height: 30px;
  width: 100%;
  max-width: 800px;
  margin: 3px auto;
  padding: 0px 4px;
  /* background-color: white; */
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 5px;
  overflow-x: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  cursor:all-scroll;
  flex-wrap: nowrap;
}

.ball-box{
  height: 30px;
  min-width: 40px;
  max-width: max-content;
  padding: 1px 5px;
  background-color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 11px;
}
.over-slider::-webkit-scrollbar{
  display: none;
}


.over-end-view{
  height: 30px;
  min-width: 40px;
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(48, 65, 110); */
  /* background-color: red; */
}

.out{
  background-image: linear-gradient(to right top, #d65555, #d55c5c, #d46262, #d36969, #d26f6f);
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.four{
  background-image: linear-gradient(to right top, #a2a2e4, #a8a8e6, #aeaee7, #b5b5e9, #bbbbea);
  color: white;

}

.six{
  background-image: linear-gradient(to right top, #60a860, #68a967, #6faa6f, #76ab76, #7dac7d);
  color: white;
}

.toss-team-block{
  height: 50px;
  width: max-content;
  padding: 0 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  border-radius: 8px;
  color: black;
  cursor: pointer;
}

.selected-team{
  background-color: rgb(142, 190, 245);
  color: white;
}
.custom-text-input{
  border: none;
  height: 50px;
  width: 100%;
  border: 1px solid rgb(178, 178, 178);
  border-radius: 8px;
  padding: 0 10px;
  margin-bottom: 1rem;
}

/* player selection styles  */
.player-selection-container{
  min-height: 100vh;
}
.player-select-button{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  max-width: 300px;
  min-width: max-content;
  /* margin: 0 auto; */
  border-radius: 8px;
  padding: 0 1rem;
  height: 80px;
  padding: 10px;
  color: black;
  cursor: pointer;
  box-shadow: 0px 0px 15px rgba(128, 130, 133, 0.542);
  margin-bottom: 1rem;
  margin-right: 1rem;
  gap: 7px;
  position: relative;
  transition: all 200ms ease-in-out;
}
.player-select-button:hover{
  background-color: rgb(148, 182, 238);
  color: white;
  transform: scale(1.1);
}
.player-name{
  font-size: 14px;
}
.player-role{
  font-size: 12px;
}
.captian-flag{
  font-size: 12px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.selected-player{
  background-color: rgb(148, 182, 238);
  color: white;
}
.selected-player-substitute{
  background-color: rgb(90, 179, 118);
  color: white;
}

.score-edit-input-container{
  height: 60px;
  width: 100px;
  /* border-radius: 6px; */
  position: absolute;
  background-color: white; 
  display: flex;
  align-items: center;
}
.score-edit_input-score, .score-edit_input-balls{
  width: 100%;
  border: none;
  height: 30px;
  background-color: rgb(227, 224, 224);
  padding-left: 10px;
}

.score-edit_input-balls{
  background-color: rgb(173, 173, 243);
}
.score-edit_send{
  min-width: 40px;
  color: black;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}
.edit-icon{
  background-color: white;
  padding: 3px;
}

.substitute-border{
  border: 5px solid green !important;
}
.match-name{
  font-weight: 600;
  color: rgb(113, 113, 228);
}
.match-name:hover{
  color: rgb(54, 54, 240);
}

.fancy-button{
  position: absolute;
  top: -100px;
  right: 0;
}
.lineup-button{
  position: absolute;
  top: -100px;
  right: 210px;
}
.sessions-button{
  position: absolute;
  top: -100px;
  right: 100px;
}

.odds-card{
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  /* background-color: red; */
}
.radio-container{
  margin-top: -0.5rem;
  gap: 1rem;
}
.odds-card:hover{
  background-image: linear-gradient(to right, #b6d9f8, #b0d6f6, #aad3f4, #a3d0f1, #9dcdef);
  color: white !important;
}
.lambi-radio{
  width: 25px;
}
input[type="radio"]{
  accent-color: black;
}
.lambi-button{
  background-image: linear-gradient(to right top, #f1a8ed, #f1a3ec, #f09fec, #f09aeb, #ef95ea);
}

.khado-button{
  background-image: linear-gradient(to right top, #b2a7f0, #afa3f1, #ac9ff2, #a89bf2, #a597f3);
}

.lambi-button:hover{
  background-image: unset;
  background-color: #e34adb !important;
}
.khado-button:hover{
  background-image: unset;
  background-color: #5d48d5 !important;
}

.lambi-selected{
  background-image: unset;
  background-color: #e34adb !important;
}

.khado-selected{
  background-image: unset;
  background-color: #5d48d5 !important;
}

.coin-input-container{
  max-width: 400px;
  width: 100%;
  margin: auto;
}

.session-text{
  width: 100px;
  border: 1px solid rgb(193, 192, 192);
  border-radius: 6px;
  padding: 0 0 0 5px;
  height: 30px;
}
.input-label{
  font-size: 14px !important;
}

.gems-package-card{
  background-color: #747474;
  border: 10px !important;
  color: white;
}

.highlighet-table-row{
  background: #f8e685 !important;
}

.bg-app-gradient{
  background: linear-gradient(to top, #20493a, #285846, #306752, #38775f, #41876c) !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a {
  background: #dbf5e3;
  color: green;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li > a:before{
  background: green;
}
.widget-stat, .pagination .page-item.active .page-link{
  background: linear-gradient(to top, #20493a, #285846, #306752, #38775f, #41876c);
}

.login-form-bx .box-skew1:after{
  background-image: linear-gradient(to top, #20493a, #285846, #306752, #38775f, #41876c);
}

.competition-card{
  border: 1px solid #cac9c9;
}
.competition-card img{
   object-fit: cover;
   margin-right: .5rem;
}

.competition-card label{
  font-size: 14px;
  color: #333;
  font-weight: 600;
}
.yes-no-switch{
  display: flex ;
  align-items: center;
  border-radius: 5px;
  background-color: rgb(212, 210, 210);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  padding: .3rem;
  height: 40px;
  width: 100px;
  position: relative;
}
.yes-no-switch > div{
  /* border: 1px solid black; */
  flex-grow: 1;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
 color: black;
  padding: .3rem;
  transition: background-color 0.3s ease;
}

.yes-no-switch__active{
  background-color: #38775f;
  color: white !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}
.yes-no-switch_background-slider{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #38775f;
    z-index: -1;
    transition: left 0.3s ease; /* Smooth transition for sliding effect */
}
.preview-container, .edit-container{
  height:700px;
  width: 100%;
  margin-top: 1rem;
  background-color: rgba(205, 242, 242, 0.374);
}

.preview-container{
  display: flex;
  align-items: flex-start;
}
.preview-left,.preview-right{
  flex-grow: 1;
  padding: .5rem 1rem;
  height: inherit;
}
.preview-right{
  flex-shrink: 0;
  max-width: 400px;

min-height: 40%;
display: flex ;
flex-direction: column;
}
.preview-right_top, .preview-right_bottom{
  flex: 1;
}
.picks-content{
  /* height: calc(100% - 50px); */
  overflow-y: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  margin-top: 10px;
  gap: 5px;
}
.picks-content span{
  padding: 5px;
  background-color: white;
  font-size: 12px;
  font-weight: 600;
}
.preview-right-header{
  height: 10px;
  background-color: transparent;
  display: flex;
  align-items: start;
  font-weight: 600;
  /* justify-content: center; */
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05); */
  font-size: smaller;
  margin-bottom: 10px;
}
.preview-left{
  border-right: 1px solid rgba(191, 247, 247, 0.948);
 position: relative;
 height: 100%;
 display: flex;
 max-width: 60%;
 flex-direction: column;
}
.preview-left_top{
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  overflow-x: hidden;
  flex: 1;
}
.preview-left_bottom{
min-height: 70px;
background-color: white;
border-radius: 8px;
width: 100%;
}
.suggestion-list{
  border: none;
  list-style-type: none;
  padding: 0;
  position: absolute;
  bottom: 90px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  max-height: 200px;
  overflow-y: auto;
}
.suggestion-list li{
  border-bottom:  .5px solid rgba(128, 128, 128, 0.261);
  padding: .5rem !important;
  font-size: small;
  
}
.input-area{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px;
}
.input-area textarea{
  max-width: 90%;
  max-height: 80px;
  border: .5px solid rgba(128, 128, 128, 0.27);
  padding: 5px;
  font-size: small;
  /* flex: .9; */
}
.fantasy-tip-card{
  max-height: fit-content !important;
  max-width: 80%;
  background-color: white;
  padding: 1rem 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  font-size: small;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem
}



.mentions {
  margin: 1em 0;
  font-size: small;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
}
.mentions--singleLine .mentions__higlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 5px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-family: monospace;
  font-size: 14pt;
  max-width: 70%;
  max-height: 80px;
  font-size: small;
  flex: .7;
  max-width: 500px;
}
.mentions--multiLine .mentions__highlighter {
  padding: 9px;
}
.mentions--multiLine .mentions__input {
  padding: 9px;
  min-height: 63px;
  outline: 0;
  border: 0;
  font-size: small;
  padding: 5px;
  border: .5px solid rgba(128, 128, 128, 0.27);
  align-self: center;
  max-width: 400px;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}
.social-links-container{
  max-width: 700px;
  margin: auto;
  padding: 2rem;
  background-color: rgba(117, 189, 149, 0.239);
  border-radius: 10px;

}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  /* background-color: #cee4e5; */
}

/* Skeleton effect */
.shimmer {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
  height: 15px;
}

.shimmer-button {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
  height: 30px;
  width: 70px;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}


.form-control:focus{
  border-color: green !important;
}

.conversation-scrollable-section{
  min-height: 100%;
}

.chat-page{
  display: flex;
  align-items: start;
  gap: 1rem;
  height: calc(100vh - 170px);
  overflow-y: hidden !important;
}

.no-conversation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f5f5f5; /* Light gray background color */
}

.message-container {
  text-align: center;
}

.message {
  font-size: 18px;
  font-weight:500;
  color: #555;
}

.chat-list{
  width: 340px;
  height: 100%;
  /* background-color: green; */
  border-radius: 12px;
  padding: 1rem 0;
}

.message-list, .message-list-no-chat{
  flex-grow: 1;
  height: 100%;
  /* background-color: rgb(157, 157, 219); */
  border-radius: 12px;
}
.message-list-no-chat{
 padding: 1rem;
}
.message-header{
  padding: 1rem;
  height: 70px;
  border-radius: 12px  12px 0 0;
 border-bottom: .5px solid rgb(178, 178, 178);
 margin-bottom: 0;

}
.chat-card{
 height: 90px;
 border-bottom: .5px solid rgb(220, 220, 220);
 padding: .5rem;
 border-radius: 0;
 /* background-color: #d9d9d942; */
 margin-bottom: 0;
 display: flex;
 align-items: center;
 flex-direction: row;
 cursor: pointer;
}
.chat-card:hover{
  background-color: #dcede0;
}

.chat-input-container{
  height: 100px;
  /* background-color: rgb(182, 182, 235); */
  border-radius: 0 0 12px  12px;  
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}
.chat-input-container_button{
  padding: .65rem 1rem;
}
.chat-input-container_input{
  padding: .5rem 1rem;
  height: 50px;
  border-color: #e6e6e6;
  box-shadow: none !important;
}
.message-list{
  display: flex ;
  flex-direction: column;
  gap: 0;
  height: 100%;
  justify-content: space-between;
 }
.message-list-scroll{
  flex-grow: 1;
  padding: 1rem 1rem 0rem 1rem;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  overflow-y: scroll;
  background-color: #f1f4f4;
}

.message-header{
  display: flex;
  align-items: start;
}
.message-header_image{
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 50%;
}


.header_name{
  color: #000;

}
.header_state, .header_time, .header_unread{
  font-size: 12px;
}
.header_unread{
   width: 20px;
   height: 20px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #2e2e2e;
   color: white;
}

.message-base {
  width: max-content;
  height: auto;
  padding: 10px;
  font-size: 14px;
  max-width: 600px;
  word-wrap: break-word;
  color: #000;
  /* // background-color: var(--primary-color); */
}
.message-time{
  font-size: 10px;
}
.my-message-border-radius {
  border-radius: 16px 0 16px 16px !important;
}

.message-border-radius {
  border-radius: 0 16px 16px 16px !important;
}

.card-icon{
  width: 50px;
  height: 50px;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(226, 226, 226) !important;
}
.content-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}